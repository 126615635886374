<template>
  <div v-if="editor">
    <div class="tiptap-editor">
      <div
        class="tiptap-editor__toolbar"
        role="toolbar"
        tabindex="-1"
        aria-label="Tiptap Editor Toolbar"
      >
        <div class="tiptap-editor__toolbar-items">
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Bold">
            <button
              @click="editor.chain().focus().toggleBold().run()"
              :disabled="!editor.can().chain().focus().toggleBold().run()"
              :class="{ 'is-active': editor.isActive('bold'), 'is-disabled': !editor.can().chain().focus().toggleBold().run() }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  d="M10.187 17H5.773c-.637 0-1.092-.138-1.364-.415-.273-.277-.409-.718-.409-1.323V4.738c0-.617.14-1.062.419-1.332.279-.27.73-.406 1.354-.406h4.68c.69 0 1.288.041 1.793.124.506.083.96.242 1.36.478.341.197.644.447.906.75a3.262 3.262 0 0 1 .808 2.162c0 1.401-.722 2.426-2.167 3.075C15.05 10.175 16 11.315 16 13.01a3.756 3.756 0 0 1-2.296 3.504 6.1 6.1 0 0 1-1.517.377c-.571.073-1.238.11-2 .11zm-.217-6.217H7v4.087h3.069c1.977 0 2.965-.69 2.965-2.072 0-.707-.256-1.22-.768-1.537-.512-.319-1.277-.478-2.296-.478zM7 5.13v3.619h2.606c.729 0 1.292-.067 1.69-.2a1.6 1.6 0 0 0 .91-.765c.165-.267.247-.566.247-.897 0-.707-.26-1.176-.778-1.409-.519-.232-1.31-.348-2.375-.348H7z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Italic">
            <button
              @click="editor.chain().focus().toggleItalic().run()"
              :disabled="!editor.can().chain().focus().toggleItalic().run()"
              :class="{ 'is-active': editor.isActive('italic'), 'is-disabled': !editor.can().chain().focus().toggleItalic().run() }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  d="m9.586 14.633.021.004c-.036.335.095.655.393.962.082.083.173.15.274.201h1.474a.6.6 0 1 1 0 1.2H5.304a.6.6 0 0 1 0-1.2h1.15c.474-.07.809-.182 1.005-.334.157-.122.291-.32.404-.597l2.416-9.55a1.053 1.053 0 0 0-.281-.823 1.12 1.12 0 0 0-.442-.296H8.15a.6.6 0 0 1 0-1.2h6.443a.6.6 0 1 1 0 1.2h-1.195c-.376.056-.65.155-.823.296-.215.175-.423.439-.623.79l-2.366 9.347z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Underline">
            <button
              @click="editor.chain().focus().toggleUnderline().run()"
              :disabled="!editor.can().chain().focus().toggleUnderline().run()"
              :class="{ 'is-active': editor.isActive('underline'), 'is-disabled': !editor.can().chain().focus().toggleUnderline().run() }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  d="M3 18v-1.5h14V18zm2.2-8V3.6c0-.4.4-.6.8-.6.3 0 .7.2.7.6v6.2c0 2 1.3 2.8 3.2 2.8 1.9 0 3.4-.9 3.4-2.9V3.6c0-.3.4-.5.8-.5.3 0 .7.2.7.5V10c0 2.7-2.2 4-4.9 4-2.6 0-4.7-1.2-4.7-4z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Strike">
            <button
              @click="editor.chain().focus().toggleStrike().run()"
              :disabled="!editor.can().chain().focus().toggleStrike().run()"
              :class="{ 'is-active': editor.isActive('strike'), 'is-disabled': !editor.can().chain().focus().toggleStrike().run() }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  d="M7 16.4c-.8-.4-1.5-.9-2.2-1.5a.6.6 0 0 1-.2-.5l.3-.6h1c1 1.2 2.1 1.7 3.7 1.7 1 0 1.8-.3 2.3-.6.6-.4.6-1.2.6-1.3.2-1.2-.9-2.1-.9-2.1h2.1c.3.7.4 1.2.4 1.7v.8l-.6 1.2c-.6.8-1.1 1-1.6 1.2a6 6 0 0 1-2.4.6c-1 0-1.8-.3-2.5-.6zM6.8 9 6 8.3c-.4-.5-.5-.8-.5-1.6 0-.7.1-1.3.5-1.8.4-.6 1-1 1.6-1.3a6.3 6.3 0 0 1 4.7 0 4 4 0 0 1 1.7 1l.3.7c0 .1.2.4-.2.7-.4.2-.9.1-1 0a3 3 0 0 0-1.2-1c-.4-.2-1-.3-2-.4-.7 0-1.4.2-2 .6-.8.6-1 .8-1 1.5 0 .8.5 1 1.2 1.5.6.4 1.1.7 1.9 1H6.8z"
                />
                <path d="M3 10.5V9h14v1.5z" />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Subscript">
            <button
              @click="editor.chain().focus().toggleSubscript().run()"
              :disabled="!editor.can().chain().focus().toggleSubscript().run()"
              :class="{ 'is-active': editor.isActive('subscript'), 'is-disabled': !editor.can().chain().focus().toggleSubscript().run() }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  d="m7.03 10.349 3.818-3.819a.8.8 0 1 1 1.132 1.132L8.16 11.48l3.819 3.818a.8.8 0 1 1-1.132 1.132L7.03 12.61l-3.818 3.82a.8.8 0 1 1-1.132-1.132L5.9 11.48 2.08 7.662A.8.8 0 1 1 3.212 6.53l3.818 3.82zm8.147 7.829h2.549c.254 0 .447.05.58.152a.49.49 0 0 1 .201.413.54.54 0 0 1-.159.393c-.105.108-.266.162-.48.162h-3.594c-.245 0-.435-.066-.572-.197a.621.621 0 0 1-.205-.463c0-.114.044-.265.132-.453a1.62 1.62 0 0 1 .288-.444c.433-.436.824-.81 1.172-1.122.348-.312.597-.517.747-.615.267-.183.49-.368.667-.553.177-.185.312-.375.405-.57.093-.194.139-.384.139-.57a1.008 1.008 0 0 0-.554-.917 1.197 1.197 0 0 0-.56-.133c-.426 0-.761.182-1.005.546a2.332 2.332 0 0 0-.164.39 1.609 1.609 0 0 1-.258.488c-.096.114-.237.17-.423.17a.558.558 0 0 1-.405-.156.568.568 0 0 1-.161-.427c0-.218.05-.446.151-.683.101-.238.252-.453.452-.646s.454-.349.762-.467a2.998 2.998 0 0 1 1.081-.178c.498 0 .923.076 1.274.228a1.916 1.916 0 0 1 1.004 1.032 1.984 1.984 0 0 1-.156 1.794c-.2.32-.405.572-.613.754-.208.182-.558.468-1.048.857-.49.39-.826.691-1.008.906a2.703 2.703 0 0 0-.24.309z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Superscript">
            <button
              @click="editor.chain().focus().toggleSuperscript().run()"
              :disabled="!editor.can().chain().focus().toggleSuperscript().run()"
              :class="{ 'is-active': editor.isActive('superscript'), 'is-disabled': !editor.can().chain().focus().toggleSuperscript().run() }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  d="M15.677 8.678h2.549c.254 0 .447.05.58.152a.49.49 0 0 1 .201.413.54.54 0 0 1-.159.393c-.105.108-.266.162-.48.162h-3.594c-.245 0-.435-.066-.572-.197a.621.621 0 0 1-.205-.463c0-.114.044-.265.132-.453a1.62 1.62 0 0 1 .288-.444c.433-.436.824-.81 1.172-1.122.348-.312.597-.517.747-.615.267-.183.49-.368.667-.553.177-.185.312-.375.405-.57.093-.194.139-.384.139-.57a1.008 1.008 0 0 0-.554-.917 1.197 1.197 0 0 0-.56-.133c-.426 0-.761.182-1.005.546a2.332 2.332 0 0 0-.164.39 1.609 1.609 0 0 1-.258.488c-.096.114-.237.17-.423.17a.558.558 0 0 1-.405-.156.568.568 0 0 1-.161-.427c0-.218.05-.446.151-.683.101-.238.252-.453.452-.646s.454-.349.762-.467a2.998 2.998 0 0 1 1.081-.178c.498 0 .923.076 1.274.228a1.916 1.916 0 0 1 1.004 1.032 1.984 1.984 0 0 1-.156 1.794c-.2.32-.405.572-.613.754-.208.182-.558.468-1.048.857-.49.39-.826.691-1.008.906a2.703 2.703 0 0 0-.24.309zM7.03 10.349l3.818-3.819a.8.8 0 1 1 1.132 1.132L8.16 11.48l3.819 3.818a.8.8 0 1 1-1.132 1.132L7.03 12.61l-3.818 3.82a.8.8 0 1 1-1.132-1.132L5.9 11.48 2.08 7.662A.8.8 0 1 1 3.212 6.53l3.818 3.82z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Code">
            <button
              @click="editor.chain().focus().toggleCode().run()"
              :disabled="!editor.can().chain().focus().toggleCode().run()"
              :class="{ 'is-active': editor.isActive('code'), 'is-disabled': !editor.can().chain().focus().toggleCode().run() }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  d="m12.5 5.7 5.2 3.9v1.3l-5.6 4c-.1.2-.3.2-.5.2-.3-.1-.6-.7-.6-1l.3-.4 4.7-3.5L11.5 7l-.2-.2c-.1-.3-.1-.6 0-.8.2-.2.5-.4.8-.4a.8.8 0 0 1 .4.1zm-5.2 0L2 9.6v1.3l5.6 4c.1.2.3.2.5.2.3-.1.7-.7.6-1 0-.1 0-.3-.2-.4l-5-3.5L8.2 7l.2-.2c.1-.3.1-.6 0-.8-.2-.2-.5-.4-.8-.4a.8.8 0 0 0-.3.1z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Remove Format">
            <button
              :disabled="!editor.isActive('bold') && !editor.isActive('italic') && !editor.isActive('underline') && !editor.isActive('strike') && !editor.isActive('subscript') && !editor.isActive('superscript')"
              @click="editor.chain().focus().unsetAllMarks().run()"
              class="tiptap-editor__button"
              :class="{ 'is-disabled': !editor.isActive('bold') && !editor.isActive('italic') && !editor.isActive('underline') && !editor.isActive('strike') && !editor.isActive('subscript') && !editor.isActive('superscript') }"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  d="M8.69 14.915c.053.052.173.083.36.093a.366.366 0 0 1 .345.485l-.003.01a.738.738 0 0 1-.697.497h-2.67a.374.374 0 0 1-.353-.496l.013-.038a.681.681 0 0 1 .644-.458c.197-.012.325-.043.386-.093a.28.28 0 0 0 .072-.11L9.592 4.5H6.269c-.359-.017-.609.013-.75.09-.142.078-.289.265-.442.563-.192.29-.516.464-.864.464H4.17a.43.43 0 0 1-.407-.569L4.46 3h13.08l-.62 2.043a.81.81 0 0 1-.775.574h-.114a.486.486 0 0 1-.486-.486c.001-.284-.054-.464-.167-.54-.112-.076-.367-.106-.766-.091h-3.28l-2.68 10.257c-.006.074.007.127.038.158zM3 17h8a.5.5 0 1 1 0 1H3a.5.5 0 1 1 0-1zm11.299 1.17a.75.75 0 1 1-1.06-1.06l1.414-1.415-1.415-1.414a.75.75 0 0 1 1.06-1.06l1.415 1.414 1.414-1.415a.75.75 0 1 1 1.06 1.06l-1.413 1.415 1.414 1.415a.75.75 0 0 1-1.06 1.06l-1.415-1.414-1.414 1.414z"
                />
              </svg>
            </button>
          </span>
          <!-- <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Clear Nodes">
            <button
              @click="editor.chain().focus().clearNodes().run()"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M10 2C10.5523 2 11 2.44772 11 3V7C11 7.55228 10.5523 8 10 8H8V10H13V9C13 8.44772 13.4477 8 14 8H20C20.5523 8 21 8.44772 21 9V13C21 13.5523 20.5523 14 20 14H14C13.4477 14 13 13.5523 13 13V12H8V18H13V17C13 16.4477 13.4477 16 14 16H20C20.5523 16 21 16.4477 21 17V21C21 21.5523 20.5523 22 20 22H14C13.4477 22 13 21.5523 13 21V20H7C6.44772 20 6 19.5523 6 19V8H4C3.44772 8 3 7.55228 3 7V3C3 2.44772 3.44772 2 4 2H10ZM19 18H15V20H19V18ZM19 10H15V12H19V10ZM9 4H5V6H9V4Z"
                />
              </svg>
            </button>
          </span>-->
          <span class="tiptap-editor__toolbar-separator"></span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Align Left">
            <button
              @click="editor.chain().focus().setTextAlign('left').run()"
              :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  d="M2 3.75c0 .414.336.75.75.75h14.5a.75.75 0 1 0 0-1.5H2.75a.75.75 0 0 0-.75.75zm0 8c0 .414.336.75.75.75h14.5a.75.75 0 1 0 0-1.5H2.75a.75.75 0 0 0-.75.75zm0 4c0 .414.336.75.75.75h9.929a.75.75 0 1 0 0-1.5H2.75a.75.75 0 0 0-.75.75zm0-8c0 .414.336.75.75.75h9.929a.75.75 0 1 0 0-1.5H2.75a.75.75 0 0 0-.75.75z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Align Center">
            <button
              @click="editor.chain().focus().setTextAlign('center').run()"
              :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  d="M2 3.75c0 .414.336.75.75.75h14.5a.75.75 0 1 0 0-1.5H2.75a.75.75 0 0 0-.75.75zm0 8c0 .414.336.75.75.75h14.5a.75.75 0 1 0 0-1.5H2.75a.75.75 0 0 0-.75.75zm2.286 4c0 .414.336.75.75.75h9.928a.75.75 0 1 0 0-1.5H5.036a.75.75 0 0 0-.75.75zm0-8c0 .414.336.75.75.75h9.928a.75.75 0 1 0 0-1.5H5.036a.75.75 0 0 0-.75.75z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Align Right">
            <button
              @click="editor.chain().focus().setTextAlign('right').run()"
              :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  d="M18 3.75a.75.75 0 0 1-.75.75H2.75a.75.75 0 1 1 0-1.5h14.5a.75.75 0 0 1 .75.75zm0 8a.75.75 0 0 1-.75.75H2.75a.75.75 0 1 1 0-1.5h14.5a.75.75 0 0 1 .75.75zm0 4a.75.75 0 0 1-.75.75H7.321a.75.75 0 1 1 0-1.5h9.929a.75.75 0 0 1 .75.75zm0-8a.75.75 0 0 1-.75.75H7.321a.75.75 0 1 1 0-1.5h9.929a.75.75 0 0 1 .75.75z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Align Justify">
            <button
              @click="editor.chain().focus().setTextAlign('justify').run()"
              :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  d="M2 3.75c0 .414.336.75.75.75h14.5a.75.75 0 1 0 0-1.5H2.75a.75.75 0 0 0-.75.75zm0 8c0 .414.336.75.75.75h14.5a.75.75 0 1 0 0-1.5H2.75a.75.75 0 0 0-.75.75zm0 4c0 .414.336.75.75.75h9.929a.75.75 0 1 0 0-1.5H2.75a.75.75 0 0 0-.75.75zm0-8c0 .414.336.75.75.75h14.5a.75.75 0 1 0 0-1.5H2.75a.75.75 0 0 0-.75.75z"
                />
              </svg>
            </button>
          </span>
          <!-- <span class="item" effect="dark" content="Bold" placement="top">
            <button @click="editor.chain().focus().unsetTextAlign().run()">unsetTextAlign</button>
          </span>-->
          <span class="tiptap-editor__toolbar-separator"></span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Heading 1">
            <button
              @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
              :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M13 20H11V13H4V20H2V4H4V11H11V4H13V20ZM21.0005 8V20H19.0005L19 10.204L17 10.74V8.67L19.5005 8H21.0005Z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Heading 2">
            <button
              @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
              :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M4 4V11H11V4H13V20H11V13H4V20H2V4H4ZM18.5 8C20.5711 8 22.25 9.67893 22.25 11.75C22.25 12.6074 21.9623 13.3976 21.4781 14.0292L21.3302 14.2102L18.0343 18H22V20H15L14.9993 18.444L19.8207 12.8981C20.0881 12.5908 20.25 12.1893 20.25 11.75C20.25 10.7835 19.4665 10 18.5 10C17.5818 10 16.8288 10.7071 16.7558 11.6065L16.75 11.75H14.75C14.75 9.67893 16.4289 8 18.5 8Z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Heading 3">
            <button
              @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
              :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M22 8L21.9984 10L19.4934 12.883C21.0823 13.3184 22.25 14.7728 22.25 16.5C22.25 18.5711 20.5711 20.25 18.5 20.25C16.674 20.25 15.1528 18.9449 14.8184 17.2166L16.7821 16.8352C16.9384 17.6413 17.6481 18.25 18.5 18.25C19.4665 18.25 20.25 17.4665 20.25 16.5C20.25 15.5335 19.4665 14.75 18.5 14.75C18.214 14.75 17.944 14.8186 17.7056 14.9403L16.3992 13.3932L19.3484 10H15V8H22ZM4 4V11H11V4H13V20H11V13H4V20H2V4H4Z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Heading 4">
            <button
              @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
              :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M13 20H11V13H4V20H2V4H4V11H11V4H13V20ZM22 8V16H23.5V18H22V20H20V18H14.5V16.66L19.5 8H22ZM20 11.133L17.19 16H20V11.133Z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Heading 5">
            <button
              @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
              :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M22 8V10H17.6769L17.2126 12.6358C17.5435 12.5472 17.8912 12.5 18.25 12.5C20.4591 12.5 22.25 14.2909 22.25 16.5C22.25 18.7091 20.4591 20.5 18.25 20.5C16.4233 20.5 14.8827 19.2756 14.4039 17.6027L16.3271 17.0519C16.5667 17.8881 17.3369 18.5 18.25 18.5C19.3546 18.5 20.25 17.6046 20.25 16.5C20.25 15.3954 19.3546 14.5 18.25 14.5C17.6194 14.5 17.057 14.7918 16.6904 15.2478L14.8803 14.3439L16 8H22ZM4 4V11H11V4H13V20H11V13H4V20H2V4H4Z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Heading 6">
            <button
              @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
              :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M21.097 8L18.499 12.5C20.7091 12.5 22.5 14.2909 22.5 16.5C22.5 18.7091 20.7091 20.5 18.5 20.5C16.2909 20.5 14.5 18.7091 14.5 16.5C14.5 15.7636 14.699 15.0737 15.0461 14.4811L18.788 8H21.097ZM4 4V11H11V4H13V20H11V13H4V20H2V4H4ZM18.5 14.5C17.3954 14.5 16.5 15.3954 16.5 16.5C16.5 17.6046 17.3954 18.5 18.5 18.5C19.6046 18.5 20.5 17.6046 20.5 16.5C20.5 15.3954 19.6046 14.5 18.5 14.5Z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Paragraph">
            <button
              @click="editor.chain().focus().setParagraph().run()"
              :class="{ 'is-active': editor.isActive('paragraph') }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M12 6V21H10V16C6.68629 16 4 13.3137 4 10C4 6.68629 6.68629 4 10 4H20V6H17V21H15V6H12ZM10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14V6Z"
                />
              </svg>
            </button>
          </span>
          <span class="tiptap-editor__toolbar-separator"></span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Bulleted List">
            <button
              @click="editor.chain().focus().toggleBulletList().run()"
              :class="{ 'is-active': editor.isActive('bulletList') }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M8 4H21V6H8V4ZM4.5 6.5C3.67157 6.5 3 5.82843 3 5C3 4.17157 3.67157 3.5 4.5 3.5C5.32843 3.5 6 4.17157 6 5C6 5.82843 5.32843 6.5 4.5 6.5ZM4.5 13.5C3.67157 13.5 3 12.8284 3 12C3 11.1716 3.67157 10.5 4.5 10.5C5.32843 10.5 6 11.1716 6 12C6 12.8284 5.32843 13.5 4.5 13.5ZM4.5 20.4C3.67157 20.4 3 19.7284 3 18.9C3 18.0716 3.67157 17.4 4.5 17.4C5.32843 17.4 6 18.0716 6 18.9C6 19.7284 5.32843 20.4 4.5 20.4ZM8 11H21V13H8V11ZM8 18H21V20H8V18Z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Numbered List">
            <button
              @click="editor.chain().focus().toggleOrderedList().run()"
              :class="{ 'is-active': editor.isActive('orderedList') }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M8 4H21V6H8V4ZM5 3V6H6V7H3V6H4V4H3V3H5ZM3 14V11.5H5V11H3V10H6V12.5H4V13H6V14H3ZM5 19.5H3V18.5H5V18H3V17H6V21H3V20H5V19.5ZM8 11H21V13H8V11ZM8 18H21V20H8V18Z"
                />
              </svg>
            </button>
          </span>
          <span class="tiptap-editor__toolbar-separator"></span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Code Block">
            <button
              @click="editor.chain().focus().toggleCodeBlock().run()"
              :class="{ 'is-active': editor.isActive('codeBlock') }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  d="M12.87 12.61a.75.75 0 0 1-.089.976l-.085.07-3.154 2.254 3.412 2.414a.75.75 0 0 1 .237.95l-.057.095a.75.75 0 0 1-.95.237l-.096-.058-4.272-3.022-.003-1.223 4.01-2.867a.75.75 0 0 1 1.047.174zm2.795-.231.095.057 4.011 2.867-.003 1.223-4.272 3.022-.095.058a.75.75 0 0 1-.88-.151l-.07-.086-.058-.095a.75.75 0 0 1 .15-.88l.087-.07 3.412-2.414-3.154-2.253-.085-.071a.75.75 0 0 1 .862-1.207zM16 0a2 2 0 0 1 2 2v9.354l-.663-.492-.837-.001V2a.5.5 0 0 0-.5-.5H2a.5.5 0 0 0-.5.5v15a.5.5 0 0 0 .5.5h3.118L7.156 19H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h14zM5.009 15l.003 1H3v-1h2.009zm2.188-2-1.471 1H5v-1h2.197zM10 11v.095L8.668 12H7v-1h3zm4-2v1H7V9h7zm0-2v1H7V7h7zm-4-2v1H5V5h5zM6 3v1H3V3h3z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Blockquote">
            <button
              @click="editor.chain().focus().toggleBlockquote().run()"
              :class="{ 'is-active': editor.isActive('blockquote') }"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M4.58341 17.3211C3.55316 16.2275 3 15 3 13.0104C3 9.51092 5.45651 6.37372 9.03059 4.82324L9.92328 6.20085C6.58804 8.00545 5.93618 10.3461 5.67564 11.8221C6.21263 11.5444 6.91558 11.4467 7.60471 11.5106C9.40908 11.6778 10.8312 13.1591 10.8312 15C10.8312 16.933 9.26416 18.5 7.33116 18.5C6.2581 18.5 5.23196 18.0096 4.58341 17.3211ZM14.5834 17.3211C13.5532 16.2275 13 15 13 13.0104C13 9.51092 15.4565 6.37372 19.0306 4.82324L19.9233 6.20085C16.588 8.00545 15.9362 10.3461 15.6756 11.8221C16.2126 11.5444 16.9156 11.4467 17.6047 11.5106C19.4091 11.6778 20.8312 13.1591 20.8312 15C20.8312 16.933 19.2642 18.5 17.3312 18.5C16.2581 18.5 15.232 18.0096 14.5834 17.3211Z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Horizontal Line">
            <button
              @click="editor.chain().focus().setHorizontalRule().run()"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path d="M2 9h16v2H2z" />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Page Break">
            <button
              @click="editor.chain().focus().setHardBreak().run()"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path d="M3.598.687h1.5v5h-1.5zm14.5 0h1.5v5h-1.5z" />
                <path d="M19.598 4.187v1.5h-16v-1.5zm-16 14.569h1.5v-5h-1.5zm14.5 0h1.5v-5h-1.5z" />
                <path
                  d="M19.598 15.256v-1.5h-16v1.5zM5.081 9h6v2h-6zm8 0h6v2h-6zm-9.483 1L0 12.5v-5z"
                />
              </svg>
            </button>
          </span>
          <template>
            <span
              tabindex="0"
              v-b-tooltip.top
              class="item"
              effect="dark"
              :title="editor.isActive('link') ? 'Unlink' : 'Link'"
            >
              <button
                v-if="!editor.isActive('link')"
                @click="toggleSetLink"
                :class="{ 'is-active': editor.isActive('link') }"
                class="tiptap-editor__button"
                type="button"
                tabindex="-1"
              >
                <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                  <path
                    d="M18.3643 15.5353L16.95 14.1211L18.3643 12.7069C20.3169 10.7543 20.3169 7.58847 18.3643 5.63585C16.4116 3.68323 13.2458 3.68323 11.2932 5.63585L9.87898 7.05007L8.46477 5.63585L9.87898 4.22164C12.6127 1.48797 17.0448 1.48797 19.7785 4.22164C22.5121 6.95531 22.5121 11.3875 19.7785 14.1211L18.3643 15.5353ZM15.5358 18.3638L14.1216 19.778C11.388 22.5117 6.9558 22.5117 4.22213 19.778C1.48846 17.0443 1.48846 12.6122 4.22213 9.87849L5.63634 8.46428L7.05055 9.87849L5.63634 11.2927C3.68372 13.2453 3.68372 16.4112 5.63634 18.3638C7.58896 20.3164 10.7548 20.3164 12.7074 18.3638L14.1216 16.9496L15.5358 18.3638ZM14.8287 7.75717L16.2429 9.17139L9.17187 16.2425L7.75766 14.8282L14.8287 7.75717Z"
                  />
                </svg>
              </button>
              <button
                v-else
                @click="editor.chain().focus().unsetLink().run()"
                :class="{ 'is-active': editor.isActive('link'), 'is-disabled': !editor.isActive('link') }"
                :disabled="!editor.isActive('link')"
                class="tiptap-editor__button"
                type="button"
                tabindex="-1"
              >
                <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                  <path
                    d="M17 17H22V19H19V22H17V17ZM7 7H2V5H5V2H7V7ZM18.364 15.5355L16.9497 14.1213L18.364 12.7071C20.3166 10.7545 20.3166 7.58866 18.364 5.63604C16.4113 3.68342 13.2455 3.68342 11.2929 5.63604L9.87868 7.05025L8.46447 5.63604L9.87868 4.22183C12.6123 1.48816 17.0445 1.48816 19.7782 4.22183C22.5118 6.9555 22.5118 11.3877 19.7782 14.1213L18.364 15.5355ZM15.5355 18.364L14.1213 19.7782C11.3877 22.5118 6.9555 22.5118 4.22183 19.7782C1.48816 17.0445 1.48816 12.6123 4.22183 9.87868L5.63604 8.46447L7.05025 9.87868L5.63604 11.2929C3.68342 13.2455 3.68342 16.4113 5.63604 18.364C7.58866 20.3166 10.7545 20.3166 12.7071 18.364L14.1213 16.9497L15.5355 18.364ZM14.8284 7.75736L16.2426 9.17157L9.17157 16.2426L7.75736 14.8284L14.8284 7.75736Z"
                  />
                </svg>
              </button>
            </span>
          </template>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Add Image">
            <button @click="addImage" class="tiptap-editor__button" type="button" tabindex="-1">
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M21 15V18H24V20H21V23H19V20H16V18H19V15H21ZM21.0082 3C21.556 3 22 3.44495 22 3.9934L22.0007 13.3417C21.3749 13.1204 20.7015 13 20 13V5H4L4.001 19L13.2929 9.70715C13.6528 9.34604 14.22 9.31823 14.6123 9.62322L14.7065 9.70772L18.2521 13.2586C15.791 14.0069 14 16.2943 14 19C14 19.7015 14.1204 20.3749 14.3417 21.0007L2.9918 21C2.44405 21 2 20.5551 2 20.0066V3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082ZM8 7C9.10457 7 10 7.89543 10 9C10 10.1046 9.10457 11 8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7Z"
                />
              </svg>
            </button>
          </span>
          <span class="tiptap-editor__toolbar-separator"></span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Insert Table">
            <button
              @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  d="M3 6v3h4V6H3zm0 4v3h4v-3H3zm0 4v3h4v-3H3zm5 3h4v-3H8v3zm5 0h4v-3h-4v3zm4-4v-3h-4v3h4zm0-4V6h-4v3h4zm1.5 8a1.5 1.5 0 0 1-1.5 1.5H3A1.5 1.5 0 0 1 1.5 17V4c.222-.863 1.068-1.5 2-1.5h13c.932 0 1.778.637 2 1.5v13zM12 13v-3H8v3h4zm0-4V6H8v3h4z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Insert Column Lefts">
            <button
              @click="editor.chain().focus().addColumnBefore().run()"
              :disabled="!editor.can().addColumnBefore()"
              :class="{'is-disabled': !editor.can().addColumnBefore()}"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M20 3C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H14C13.4477 21 13 20.5523 13 20V4C13 3.44772 13.4477 3 14 3H20ZM19 5H15V19H19V5ZM6 7C8.76142 7 11 9.23858 11 12C11 14.7614 8.76142 17 6 17C3.23858 17 1 14.7614 1 12C1 9.23858 3.23858 7 6 7ZM7 9H5V10.999L3 11V13L5 12.999V15H7V12.999L9 13V11L7 10.999V9Z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Insert Column Right">
            <button
              @click="editor.chain().focus().addColumnAfter().run()"
              :disabled="!editor.can().addColumnAfter()"
              :class="{'is-disabled': !editor.can().addColumnAfter()}"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M10 3C10.5523 3 11 3.44772 11 4V20C11 20.5523 10.5523 21 10 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H10ZM9 5H5V19H9V5ZM18 7C20.7614 7 23 9.23858 23 12C23 14.7614 20.7614 17 18 17C15.2386 17 13 14.7614 13 12C13 9.23858 15.2386 7 18 7ZM19 9H17V10.999L15 11V13L17 12.999V15H19V12.999L21 13V11L19 10.999V9Z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Insert Column Above">
            <button
              @click="editor.chain().focus().addRowBefore().run()"
              :disabled="!editor.can().addRowBefore()"
              :class="{'is-disabled': !editor.can().addRowBefore()}"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M20 13C20.5523 13 21 13.4477 21 14V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V14C3 13.4477 3.44772 13 4 13H20ZM19 15H5V19H19V15ZM12 1C14.7614 1 17 3.23858 17 6C17 8.76142 14.7614 11 12 11C9.23858 11 7 8.76142 7 6C7 3.23858 9.23858 1 12 1ZM13 3H11V4.999L9 5V7L11 6.999V9H13V6.999L15 7V5L13 4.999V3Z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Insert Column Below">
            <button
              @click="editor.chain().focus().addRowAfter().run()"
              :disabled="!editor.can().addRowAfter()"
              :class="{'is-disabled': !editor.can().addRowAfter()}"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M12 13C14.7614 13 17 15.2386 17 18C17 20.7614 14.7614 23 12 23C9.23858 23 7 20.7614 7 18C7 15.2386 9.23858 13 12 13ZM13 15H11V16.999L9 17V19L11 18.999V21H13V18.999L15 19V17L13 16.999V15ZM20 3C20.5523 3 21 3.44772 21 4V10C21 10.5523 20.5523 11 20 11H4C3.44772 11 3 10.5523 3 10V4C3 3.44772 3.44772 3 4 3H20ZM5 5V9H19V5H5Z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Delete Column">
            <button
              @click="editor.chain().focus().deleteColumn().run()"
              :disabled="!editor.can().deleteColumn()"
              :class="{'is-disabled': !editor.can().deleteColumn()}"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M12 3C12.5523 3 13 3.44772 13 4L12.9998 11.9998C13.8355 11.372 14.8743 11 16 11C18.7614 11 21 13.2386 21 16C21 18.7614 18.7614 21 16 21C14.9681 21 14.0092 20.6874 13.2129 20.1518L13 20C13 20.5523 12.5523 21 12 21H6C5.44772 21 5 20.5523 5 20V4C5 3.44772 5.44772 3 6 3H12ZM11 5H7V19H11V5ZM19 15H13V17H19V15Z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Delete Row">
            <button
              @click="editor.chain().focus().deleteRow().run()"
              :disabled="!editor.can().deleteRow()"
              :class="{'is-disabled': !editor.can().deleteRow()}"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 24 24">
                <path
                  d="M20 5C20.5523 5 21 5.44772 21 6V12C21 12.5523 20.5523 13 20 13C20.628 13.8355 21 14.8743 21 16C21 18.7614 18.7614 21 16 21C13.2386 21 11 18.7614 11 16C11 14.8743 11.372 13.8355 11.9998 12.9998L4 13C3.44772 13 3 12.5523 3 12V6C3 5.44772 3.44772 5 4 5H20ZM13 15V17H19V15H13ZM19 7H5V11H19V7Z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Merge or Split">
            <button
              @click="editor.chain().focus().mergeOrSplit().run()"
              :disabled="!editor.can().mergeOrSplit()"
              :class="{'is-disabled': !editor.can().mergeOrSplit()}"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  d="M2.5 1h15A1.5 1.5 0 0 1 19 2.5v15a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 1 17.5v-15A1.5 1.5 0 0 1 2.5 1zM2 2v16h16V2H2z"
                  opacity=".6"
                />
                <path d="M7 2h1v16H7V2zm5 0h1v7h-1V2zm6 5v1H2V7h16zM8 12v1H2v-1h6z" opacity=".6" />
                <path
                  d="M7 7h12a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1zm1 2v9h10V9H8z"
                />
              </svg>
            </button>
          </span>
          <!-- <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Delete Table"> 
            <button
              @click="editor.chain().focus().deleteTable().run()"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >deleteTable</button>
          </span>-->
          <!-- <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Merge Cell"> 
            <button
              @click="editor.chain().focus().mergeCells().run()"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >mergeCells</button>
          </span>-->
          <!-- <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Split Cell"> 
            <button
              @click="editor.chain().focus().splitCell().run()"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >splitCell</button>
          </span>-->
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Header Column">
            <button
              @click="editor.chain().focus().toggleHeaderColumn().run()"
              :disabled="!editor.can().toggleHeaderColumn()"
              :class="{'is-disabled': !editor.can().toggleHeaderColumn()}"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  opacity="0.6"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18 2H2V18H18V2ZM2 1C1.44772 1 1 1.44772 1 2V18C1 18.5523 1.44772 19 2 19H18C18.5523 19 19 18.5523 19 18V2C19 1.44772 18.5523 1 18 1H2Z"
                />
                <path
                  opacity="0.6"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7 7V2H8V7H12V2H13V7H18V8H13L13 12H18V13H13V18H12V13H8V18H7V13H2V12H7L7 8H2V7H7ZM8 8L8 12H12L12 8H8Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 2V18H6V2H2ZM1 0C0.447715 0 0 0.447716 0 1V19C0 19.5523 0.447715 20 1 20H7C7.55228 20 8 19.5523 8 19V1C8 0.447715 7.55228 0 7 0H1Z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Header Row">
            <button
              @click="editor.chain().focus().toggleHeaderRow().run()"
              :disabled="!editor.can().toggleHeaderRow()"
              :class="{'is-disabled': !editor.can().toggleHeaderRow()}"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  opacity="0.6"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18 2H2V18H18V2ZM2 1C1.44772 1 1 1.44772 1 2V18C1 18.5523 1.44772 19 2 19H18C18.5523 19 19 18.5523 19 18V2C19 1.44772 18.5523 1 18 1H2Z"
                />
                <path
                  opacity="0.6"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7 7V2H8V7H12V2H13V7H18V8H13L13 12H18V13H13V18H12V13H8V18H7V13H2V12H7L7 8H2V7H7ZM8 8L8 12H12L12 8H8Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 2V6H18V2H2ZM1 0C0.447715 0 0 0.447715 0 1V7C0 7.55228 0.447716 8 1 8H19C19.5523 8 20 7.55228 20 7V1C20 0.447715 19.5523 0 19 0H1Z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Header Cell">
            <button
              @click="editor.chain().focus().toggleHeaderCell().run()"
              :disabled="!editor.can().toggleHeaderCell()"
              :class="{'is-disabled': !editor.can().toggleHeaderCell()}"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  opacity="0.6"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18 2H2V18H18V2ZM2 1C1.44772 1 1 1.44772 1 2V18C1 18.5523 1.44772 19 2 19H18C18.5523 19 19 18.5523 19 18V2C19 1.44772 18.5523 1 18 1H2Z"
                />
                <path
                  opacity="0.6"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7 7V2H8V7H12V2H13V7H18V8H13L13 12H18V13H13V18H12V13H8V18H7V13H2V12H7L7 8H2V7H7ZM8 8L8 12H12L12 8H8Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 8V12H12V8H8ZM7 6C6.44772 6 6 6.44772 6 7V13C6 13.5523 6.44772 14 7 14H13C13.5523 14 14 13.5523 14 13V7C14 6.44772 13.5523 6 13 6H7Z"
                />
              </svg>
            </button>
          </span>
          <!-- <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Set Cell Attributes"> 
            <button
              @click="editor.chain().focus().setCellAttribute('colspan', 2).run()"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >setCellAttribute</button>
          </span>-->
          <!-- <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Fix Tables"> 
            <button
              @click="editor.chain().focus().fixTables().run()"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >fixTables</button>
          </span>-->
          <!-- <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Next Cell"> 
            <button
              @click="editor.chain().focus().goToNextCell().run()"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >goToNextCell</button>
          </span>-->
          <!-- <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Previous Cell"> 
            <button
              @click="editor.chain().focus().goToPreviousCell().run()"
              class="tiptap-editor__button"
              type="button"
              tabindex="-1"
            >goToPreviousCell</button>
          </span>-->
          <span class="tiptap-editor__toolbar-separator"></span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Undo">
            <button
              @click="editor.chain().focus().undo().run()"
              :disabled="!editor.can().chain().focus().undo().run()"
              class="tiptap-editor__button"
              :class="{ 'is-disabled': !editor.can().chain().focus().undo().run() }"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  d="m5.042 9.367 2.189 1.837a.75.75 0 0 1-.965 1.149l-3.788-3.18a.747.747 0 0 1-.21-.284.75.75 0 0 1 .17-.945L6.23 4.762a.75.75 0 1 1 .964 1.15L4.863 7.866h8.917A.75.75 0 0 1 14 7.9a4 4 0 1 1-1.477 7.718l.344-1.489a2.5 2.5 0 1 0 1.094-4.73l.008-.032H5.042z"
                />
              </svg>
            </button>
          </span>
          <span tabindex="0" v-b-tooltip.top class="item" effect="dark" title="Redo">
            <button
              @click="editor.chain().focus().redo().run()"
              :disabled="!editor.can().chain().focus().redo().run()"
              class="tiptap-editor__button"
              :class="{ 'is-disabled': !editor.can().chain().focus().redo().run() }"
              type="button"
              tabindex="-1"
            >
              <svg class="tiptap-editor__icon" viewBox="0 0 20 20">
                <path
                  d="m14.958 9.367-2.189 1.837a.75.75 0 0 0 .965 1.149l3.788-3.18a.747.747 0 0 0 .21-.284.75.75 0 0 0-.17-.945L13.77 4.762a.75.75 0 1 0-.964 1.15l2.331 1.955H6.22A.75.75 0 0 0 6 7.9a4 4 0 1 0 1.477 7.718l-.344-1.489A2.5 2.5 0 1 1 6.039 9.4l-.008-.032h8.927z"
                />
              </svg>
            </button>
          </span>
        </div>
      </div>

      <editor-content class="tiptap-editor__content" :editor="editor" />
    </div>
  </div>
</template>

<script>
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import Underline from "@tiptap/extension-underline";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import TextAlign from "@tiptap/extension-text-align";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Link from "@tiptap/extension-link";
import { Editor, EditorContent, VueNodeViewRenderer } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import CodeBlockComponent from "./CodeBlockComponent.vue";

// load all highlight.js languages
// import { lowlight } from 'lowlight'

// load specific languages only
import { lowlight } from "lowlight/lib/core";
import css from "highlight.js/lib/languages/css";
import js from "highlight.js/lib/languages/javascript";
import ts from "highlight.js/lib/languages/typescript";
import html from "highlight.js/lib/languages/xml";
import curl from "highlightjs-curl";
import ImageCustomExtension from "./Extensions/ImageCustomExtension";

lowlight.registerLanguage("html", html);
lowlight.registerLanguage("css", css);
lowlight.registerLanguage("js", js);
lowlight.registerLanguage("ts", ts);
lowlight.registerLanguage("curl", curl);

export default {
  name: "TiptapEditor",
  components: {
    EditorContent
  },
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      editor: null
    };
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    }
  },
  mounted() {
    this.editor = new Editor({
      content: this.value || "",
      extensions: [
        StarterKit.configure({
          document: true,
          dropcursor: true,
          gapcursor: true
        }),
        Underline,
        Subscript,
        Superscript,
        TextAlign.configure({
          types: ["heading", "paragraph"]
        }),
        Table.configure({
          resizable: true
        }),
        TableRow,
        TableHeader,
        TableCell,
        CodeBlockLowlight.extend({
          addNodeView() {
            return VueNodeViewRenderer(CodeBlockComponent);
          }
        }).configure({ lowlight }),
        Link.configure({
          openOnClick: false
        }),
        ImageCustomExtension.configure({
          inline: true,
          allowBase64: true
        })
      ],
      onUpdate: () => {
        // HTML
        this.$emit("setValue", this.editor.getHTML() || "");

        // JSON
        // this.$emit('input', this.editor.getJSON())
      }
    });
  },
  methods: {
    toggleSetLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === "") {
        this.editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .unsetLink()
          .run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
    addImage() {
      const url = window.prompt("URL");

      if (url) {
        this.editor
          .chain()
          .focus()
          .setImage({ src: url })
          .run();
      }
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  }
};
</script>

<style lang="scss" scoped>
.tiptap-editor {
  border: 1px solid #ccced1;

  &__toolbar {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    border-bottom: 1px solid #ccced1;
    padding: 0 calc(0.6em * 0.5);
    user-select: none;

    &-items {
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      flex-grow: 1;

      & > :not(.tiptap-editor__toolbar-line-break) {
        margin-bottom: calc(0.6em * 0.5);
        margin-top: calc(0.6em * 0.5);
        margin-right: calc(0.6em * 0.5);
      }
    }

    &-separator {
      align-self: stretch;
      background: #ccced1;
      min-width: 1px;
      width: 1px;
      margin-left: calc(0.6em * 0.5);
      margin-right: calc((0.6em * 0.5) * 2) !important;
    }
  }

  &__content {
    font-size: 16px;
    line-height: 1.6;
  }

  &__button {
    -webkit-appearance: none;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: inherit;
    line-height: 1;
    min-height: 30px;
    min-width: 30px;
    padding: calc(0.6em * 0.3);
    text-align: center;
    transition: box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;
    vertical-align: middle;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    position: relative;
    user-select: none;
    border-radius: 2px;

    &:hover {
      background-color: #e0e0e0;
    }

    &.is-active {
      color: #2977ff;
      background-color: #f0f7ff;
    }

    &.is-disabled {
      opacity: 0.3;
      background-color: #667085 !important;
      cursor: not-allowed;
    }
  }

  &__icon {
    font-size: 0.8333350694em;
    height: 20px;
    width: 20px;
    will-change: transform;
    vertical-align: middle;
    cursor: inherit;
    color: inherit;
    &:not([fill]) {
      fill: currentColor;
    }
  }

  ::v-deep .ProseMirror {
    padding: 1em;

    > * + * {
      margin-top: 0.75em;
    }

    ul,
    ol {
      padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }

    pre {
      // background: hsla(0, 0%, 78%, 0.3);
      // color: #353535;
      background: #0d0d0d;
      color: #fff;
      font-family: "JetBrainsMono", monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }

      .hljs-comment,
      .hljs-quote {
        color: #616161;
      }

      .hljs-variable,
      .hljs-template-variable,
      .hljs-attribute,
      .hljs-tag,
      .hljs-name,
      .hljs-regexp,
      .hljs-link,
      .hljs-name,
      .hljs-selector-id,
      .hljs-selector-class {
        color: #f98181;
      }

      .hljs-number,
      .hljs-meta,
      .hljs-built_in,
      .hljs-builtin-name,
      .hljs-literal,
      .hljs-type,
      .hljs-params {
        color: #fbbc88;
      }

      .hljs-string,
      .hljs-symbol,
      .hljs-bullet {
        color: #b9f18d;
      }

      .hljs-title,
      .hljs-section {
        color: #faf594;
      }

      .hljs-keyword,
      .hljs-selector-tag {
        color: #70cff8;
      }

      .hljs-emphasis {
        font-style: italic;
      }

      .hljs-strong {
        font-weight: 700;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#0d0d0d, 0.1);
      margin: 2rem 0;
    }

    &-focused {
      outline: none;
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 0;
      overflow: hidden;

      td,
      th {
        min-width: 1em;
        border: 2px solid #ced4da;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;

        > * {
          margin-bottom: 0;
        }
      }

      th {
        font-weight: bold;
        text-align: left;
        background-color: #f1f3f5;
      }

      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
      }

      .column-resize-handle {
        position: absolute;
        right: -2px;
        top: 0;
        bottom: -2px;
        width: 4px;
        background-color: #adf;
        pointer-events: none;
      }

      p {
        margin: 0;
      }
    }

    &-selectednode {
      border: 1px solid #adf;
    }
  }
}

.tableWrapper {
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>
