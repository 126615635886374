<template>
  <div class="wrapper d-flex">
    <p class="mr-50 title">{{ $t('Processing Staffs') }}:</p>
    <b-container v-for="(follower, index) in followerArr" :key="follower.id">
      <b-row>
        <b-col
          class="px-0"
          style="minWidth: calc(40px + 1rem); maxWidth: calc(40px + 1rem); paddingLeft: 7px !important;"
        >
          <b-img
            v-if="follower.user ? follower.user.avatar : follower.avatar"
            :src="appUtils.getImageURL(follower.user ? follower.user.avatar : follower.avatar)"
            @error="$set(follower.user, index, { ...follower.user, avatar: false })"
            fluid
            alt=""
            rounded="circle"
            style="width: 40px; height: 40px"
          ></b-img>
          <span
            v-else
            class="b-avatar badge-minimal badge-light-primary rounded-circle"
            style="width: 40px; height: 40px"
          >
            <svg
              viewBox="0 0 16 16"
              width="1em"
              height="1em"
              focusable="false"
              role="img"
              aria-label="person fill"
              aria-hidden="true"
              alt="avatar"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              class="bi-person-fill b-icon bi"
            >
              <g>
                <path
                  fill-rule="evenodd"
                  d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                ></path>
              </g>
            </svg>
            <span
              class="b-avatar-badge badge-success"
              style="font-size: calc(11.2px); bottom: 0px; right: 0px"
            >
            </span>
          </span>
        </b-col>
        <b-col
          class="px-1 d-flex flex-column"
          style="border-radius: 10px"
        >
          <p class="mb-0">
            <b style="margin-right: 5px;">{{ follower.user ? follower.user.name : follower.name }}</b>
          </p>
          <p class="mb-0 text-black">
            {{ follower.user ? follower.user.email : follower.email }}
          </p>
        </b-col>
      </b-row>
    </b-container>
    <b-button @click="handleClickBellIcon" block variant="primary">{{isFollowed?"Unfollow":"Follow"}}</b-button>
  </div>
</template>
  
<script>
import appUtils from "@/utils/appUtils";

export default {
  name: "OrderFollowerInformation",
  props: ["followers"],
  data() {
    return {
      appUtils,
      user: {},
      followerArr: []
    }
  },
  async created() {
    this.user = appUtils.getLocalUser()
    this.followerArr = [...this.followers]
  },
  computed: {
    isFollowed() {
      return this.followerArr?.map(f => f.user_id || f.id).includes(this.user.id) || false
    },
  },
  methods: {
    async handleClickBellIcon() {
      const param = {
        object_id: [+this.$route.params.id],
        type: this.user?.processors?.length ? 2 : 1
      }
      if (this.isFollowed) {
        await this.$rf
          .getRequest("CommonRequest")
          .unfollowOrders(param)
          .then(() => {
            this.followerArr = this.followerArr.filter(f => (f.user_id||f.id) !== this.user.id)
          })
      } else {
        await this.$rf
          .getRequest("CommonRequest")
          .followOrders(param)
          .then(() => {
            this.followerArr = [...this.followerArr, this.user]
          })
      }
    },
  }
};
</script>
  
<style lang="scss" scoped>
.wrapper {
  flex-direction: column;
  gap: 15px;
}
.title {
  font-size: 18px;
  font-weight: 600;
}
</style>
  